import 'regenerator-runtime/runtime';
import Vue from 'vue';

// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
// import 'select2/dist/css/select2.min.css';
import jQuery from 'jquery';

import store from '../../store/store';
import Formatter from '../../helpers/Formatter';
import '../../helpers/sentry';
import 'bootstrap';

// Mixins
import '../../components/mixins/Capitalize';
import '../../components/mixins/Translator';
import '../../components/mixins/Toaster';

import './components.registration';
import router from '../../router/front';

// Directives
import '../../directives/sortable';

// select2.js
window.global ||= window;
global.jQuery = jQuery;
global.jQuery = jQuery;
const $ = jQuery;
Object.assign(window, { $: jQuery, jQuery });

$((jq) => {
  import('select2/dist/js/select2.full').then((S) => {
    S.default();

    jQuery.fn.select2.defaults.set('theme', 'bootstrap');
    jQuery.fn.select2.defaults.set('language', 'fr');
    jq('.select2').select2();
  });
});

window.vm = new Vue({
  el: '#app',
  router,
  store,
  data() {
    return {
      formatter: Formatter,
      width: window.innerWidth,
      resizeTimeout: null,
    };
  },
  computed: {
    isMobile() {
      return this.width < 576;
    },
  },
  mounted() {
    window.onresize = this.setWindowWidth;
  },
  methods: {
    setWindowWidth() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.width = window.innerWidth;
      }, 200);
    },
  },
});
